/* fallback fonts */
@font-face {
	font-family: "PlayfairDisplayFallback";
	src: local(Times New Roman);
	size-adjust: 110%;
	ascent-override: 95%;
	descent-override: 21%;
	line-gap-override: 5%;
}

@font-face {
	font-family: "OpenSansFallback";
	src: local(Arial);
	size-adjust: 103%;
	ascent-override: 100%;
	descent-override: normal;
	line-gap-override: normal;
}

/* global scope styles */
:root {
	font-family: var(--font-1);
	word-break: break-word;
}

.container {
	margin-inline: auto;
	width: 100%;
	max-width: var(--mobile-max-width);
	padding-inline: var(--spc-2xs);

	@media (--tablet) {
		max-width: var(--tablet-max-width);
		padding-inline: var(--spc-l);
	}

	@media (--desktop) {
		max-width: var(--desktop-max-width);
		padding-inline: var(--spc-4xl);
	}

	@media (--big-screen) {
		max-width: var(--big-screen-max-width);
		padding-inline: 0;
	}
}

.debug {
	border: 4px dashed red;
}

* {
	outline-color: var(--focus-1);
}

/* study plans */
.dicoco {
	color: #a36200;
}

.dihape {
	color: #18788b;
}

.dihape-chs {
	color: #7ba4db;
}

.deca {
	color: #a00080;
}

/* Quality Assurance and Prospective Studies Service pages */
.cajaDoct.enlacemenuindice {
	display: inline;
}

/* AgreementsMap */
.gm-style .gm-style-iw-c {
	border-radius: 0;
	border: 1px solid #181818;
}

.gm-style .gm-style-iw-tc::after {
	background: #181818;
}

.gm-style-iw-chr {
	margin-bottom: -16px;
}

.gm-style .gm-style-iw-d {
	margin-left: -2px;
}
