/*

  Theme: Onexed theme

  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------

  To generate this file:
  1 - Edit `src/themes/onexed-theme` files
  2 - Run `yarn build:themes`

*/

[data-theme="onexed-theme"] {
	/* fontFamily */
	--font-2: DomaineText, Times New Roman, serif;
}
[data-theme="onexed-theme"][data-subtheme="accent"],
[data-theme="onexed-theme"] [data-subtheme="accent"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #426001;
	--error: #be0044;
	--focus-1: #db50fe;
	--focus-2: #db50fe;
	/* text */
	--text-1: #181818;
	--text-2: #707070;
	--text-3: #4d4d4d;
	--text-4: #ffb81c;
	--text-5: #7ba4db;
	--text-6: #0e3e7a;
	--text-7: #a4123f;
	--text-8: #a4123f;
	--text-inverse: #ffffff;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #181818;
	--icon-inverse: #ffffff;
	--icon-disabled: #777777;
	/* backgroundButton */
	--bg-button-1: #efb546;
	--bg-button-1-hover: #efb546;
	--bg-button-1-active: #ffc649;
	--bg-button-1-focus: #ffb81c;
	--bg-button-1-disabled: #cecece;
	--bg-button-2: #54565b;
	--bg-button-2-hover: #383838;
	--bg-button-2-active: #000000;
	--bg-button-2-focus: #181818;
	--bg-button-2-disabled: #cecece;
	--bg-button-3: transparent;
	--bg-button-3-hover: #ffffff;
	--bg-button-3-active: #f6f5ee;
	--bg-button-3-focus: #f6f5ee;
	--bg-button-3-disabled: #cecece;
	--bg-link-button-4: #efb546;
	--bg-link-button-4-hover: #e0a21a;
	--bg-link-button-4-active: #ffc649;
	--bg-link-button-4-focus: #ffb81c;
	--bg-link-button-4-disabled: #cecece;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #db50fe;
	--border-button-1-disabled: #999999;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: #db50fe;
	--border-button-2-disabled: #999999;
	--border-button-3: #54565b;
	--border-button-3-hover: #54565b;
	--border-button-3-active: #54565b;
	--border-button-3-focus: #db50fe;
	--border-button-3-disabled: #999999;
	/* textButton */
	--text-button-1: #181818;
	--text-button-1-hover: #181818;
	--text-button-1-active: #181818;
	--text-button-1-focus: #181818;
	--text-button-1-disabled: #999999;
	--text-button-2: #ffffff;
	--text-button-2-hover: #ffffff;
	--text-button-2-active: #ffffff;
	--text-button-2-focus: #ffffff;
	--text-button-2-disabled: #999999;
	--text-button-3: #54565b;
	--text-button-3-hover: #54565b;
	--text-button-3-active: #54565b;
	--text-button-3-focus: #54565b;
	--text-button-3-disabled: #999999;
	--text-link-button-4: #181818;
	--text-link-button-4-hover: #181818;
	--text-link-button-4-active: #181818;
	--text-link-button-4-focus: #181818;
	--text-link-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #273878;
	--text-link-1-hover: #192a6b;
	--text-link-1-active: #192a6b;
	--text-link-1-focus: #273878;
	--text-link-1-disabled: #999999;
	--text-link-menu: #181818;
	--text-link-menu-hover: #464646;
	--text-link-menu-active: #181818;
	--text-link-menu-focus: #181818;
	--text-link-menu-disabled: #999999;
	/* border */
	--border-1: #e2e2e2;
	--border-2: #181818;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-1-card-hover: #f2f2f2;
	--bg-2: #f1f1f1;
	--bg-3: #f2f2f2;
	--bg-4: #54565b;
	--bg-disabled: #e0e0e0;
	/* brandColors */
	--brand-1: #181818;
	/* support */
	--success: #588200;
	--focus-1: #a62bc6;
	--focus-2: #a62bc6;
	/* text */
	--text-1: #181818;
	--text-2: #ffffff;
	--text-3: #181818;
	--text-4: #181818;
	--text-5: #181818;
	--text-6: #181818;
	--text-7: #181818;
	--text-8: #181818;
	--text-inverse: #000000;
	--text-disabled: #777777;
	/* icon */
	--icon-disabled: #606060;
	/* backgroundButton */
	--bg-button-1: #d1981b;
	--bg-button-1-hover: #e8a81c;
	--bg-button-1-disabled: #a4a4a4;
	--bg-button-2-hover: #414141;
	--bg-button-2-disabled: #a4a4a4;
	--bg-button-3-hover: rgba(255, 255, 255, 0.5);
	--bg-button-3-active: rgba(255, 255, 255, 0.7);
	--bg-button-3-focus: #ffb81c;
	--bg-button-3-disabled: #a4a4a4;
	--bg-link-button-4: #ffffff;
	--bg-link-button-4-hover: #ffffff;
	--bg-link-button-4-active: #ffffff;
	--bg-link-button-4-focus: #ffffff;
	--bg-link-button-4-disabled: #a4a4a4;
	/* borderButton */
	--border-button-1-focus: ##a62bc6;
	--border-button-1-disabled: transparent;
	--border-button-2-focus: ##a62bc6;
	--border-button-2-disabled: transparent;
	--border-button-3-focus: ##a62bc6;
	--border-button-3-disabled: transparent;
	/* textButton */
	--text-button-1-disabled: #5c5c5c;
	--text-button-2-disabled: #5c5c5c;
	--text-button-3: #181818;
	--text-button-3-hover: #181818;
	--text-button-3-active: #181818;
	--text-button-3-focus: #181818;
	--text-button-3-disabled: #5c5c5c;
	--text-link-button-4-hover: #464646;
	--text-link-button-4-active: #ffffff;
	--text-link-button-4-disabled: #777777;
	/* textLink */
	--text-link-1: #ffffff;
	--text-link-1-hover: #ffffff;
	--text-link-1-active: #ffffff;
	--text-link-1-focus: #ffffff;
	--text-link-menu-active: #000000;
	/* border */
	--border-1: #181818;
	/* background */
	--bg-ui: #efb546;
	--bg-1: #efb546;
	--bg-1-card-hover: #f1f1f1;
	--bg-2: #efb546;
	--bg-3: #f1f1f1;
	--bg-disabled: #bbbbbb;
}
[data-theme="onexed-theme"][data-subtheme="accent-alt"],
[data-theme="onexed-theme"] [data-subtheme="accent-alt"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #426001;
	--error: #be0044;
	--focus-1: #db50fe;
	--focus-2: #db50fe;
	/* text */
	--text-1: #181818;
	--text-2: #707070;
	--text-3: #4d4d4d;
	--text-4: #ffb81c;
	--text-5: #7ba4db;
	--text-6: #0e3e7a;
	--text-7: #a4123f;
	--text-8: #a4123f;
	--text-inverse: #ffffff;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #181818;
	--icon-inverse: #ffffff;
	--icon-disabled: #777777;
	/* backgroundButton */
	--bg-button-1: #efb546;
	--bg-button-1-hover: #efb546;
	--bg-button-1-active: #ffc649;
	--bg-button-1-focus: #ffb81c;
	--bg-button-1-disabled: #cecece;
	--bg-button-2: #54565b;
	--bg-button-2-hover: #383838;
	--bg-button-2-active: #000000;
	--bg-button-2-focus: #181818;
	--bg-button-2-disabled: #cecece;
	--bg-button-3: transparent;
	--bg-button-3-hover: #ffffff;
	--bg-button-3-active: #f6f5ee;
	--bg-button-3-focus: #f6f5ee;
	--bg-button-3-disabled: #cecece;
	--bg-link-button-4: #efb546;
	--bg-link-button-4-hover: #e0a21a;
	--bg-link-button-4-active: #ffc649;
	--bg-link-button-4-focus: #ffb81c;
	--bg-link-button-4-disabled: #cecece;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #db50fe;
	--border-button-1-disabled: #999999;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: #db50fe;
	--border-button-2-disabled: #999999;
	--border-button-3: #54565b;
	--border-button-3-hover: #54565b;
	--border-button-3-active: #54565b;
	--border-button-3-focus: #db50fe;
	--border-button-3-disabled: #999999;
	/* textButton */
	--text-button-1: #181818;
	--text-button-1-hover: #181818;
	--text-button-1-active: #181818;
	--text-button-1-focus: #181818;
	--text-button-1-disabled: #999999;
	--text-button-2: #ffffff;
	--text-button-2-hover: #ffffff;
	--text-button-2-active: #ffffff;
	--text-button-2-focus: #ffffff;
	--text-button-2-disabled: #999999;
	--text-button-3: #54565b;
	--text-button-3-hover: #54565b;
	--text-button-3-active: #54565b;
	--text-button-3-focus: #54565b;
	--text-button-3-disabled: #999999;
	--text-link-button-4: #181818;
	--text-link-button-4-hover: #181818;
	--text-link-button-4-active: #181818;
	--text-link-button-4-focus: #181818;
	--text-link-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #273878;
	--text-link-1-hover: #192a6b;
	--text-link-1-active: #192a6b;
	--text-link-1-focus: #273878;
	--text-link-1-disabled: #999999;
	--text-link-menu: #181818;
	--text-link-menu-hover: #464646;
	--text-link-menu-active: #181818;
	--text-link-menu-focus: #181818;
	--text-link-menu-disabled: #999999;
	/* border */
	--border-1: #e2e2e2;
	--border-2: #181818;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-1-card-hover: #f2f2f2;
	--bg-2: #f1f1f1;
	--bg-3: #f2f2f2;
	--bg-4: #54565b;
	--bg-disabled: #e0e0e0;
	/* brandColors */
	--brand-1: #181818;
	/* background */
	--bg-ui: #f2f2f2;
	--bg-1: #f2f2f2;
	--bg-1-card-hover: #ffffff;
	--bg-2: #181818;
}
[data-theme="onexed-theme"][data-subtheme="default"],
[data-theme="onexed-theme"] [data-subtheme="default"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #426001;
	--error: #be0044;
	--focus-1: #db50fe;
	--focus-2: #db50fe;
	/* text */
	--text-1: #181818;
	--text-2: #707070;
	--text-3: #4d4d4d;
	--text-4: #ffb81c;
	--text-5: #7ba4db;
	--text-6: #0e3e7a;
	--text-7: #a4123f;
	--text-8: #a4123f;
	--text-inverse: #ffffff;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #181818;
	--icon-inverse: #ffffff;
	--icon-disabled: #777777;
	/* backgroundButton */
	--bg-button-1: #efb546;
	--bg-button-1-hover: #efb546;
	--bg-button-1-active: #ffc649;
	--bg-button-1-focus: #ffb81c;
	--bg-button-1-disabled: #cecece;
	--bg-button-2: #54565b;
	--bg-button-2-hover: #383838;
	--bg-button-2-active: #000000;
	--bg-button-2-focus: #181818;
	--bg-button-2-disabled: #cecece;
	--bg-button-3: transparent;
	--bg-button-3-hover: #ffffff;
	--bg-button-3-active: #f6f5ee;
	--bg-button-3-focus: #f6f5ee;
	--bg-button-3-disabled: #cecece;
	--bg-link-button-4: #efb546;
	--bg-link-button-4-hover: #e0a21a;
	--bg-link-button-4-active: #ffc649;
	--bg-link-button-4-focus: #ffb81c;
	--bg-link-button-4-disabled: #cecece;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #db50fe;
	--border-button-1-disabled: #999999;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: #db50fe;
	--border-button-2-disabled: #999999;
	--border-button-3: #54565b;
	--border-button-3-hover: #54565b;
	--border-button-3-active: #54565b;
	--border-button-3-focus: #db50fe;
	--border-button-3-disabled: #999999;
	/* textButton */
	--text-button-1: #181818;
	--text-button-1-hover: #181818;
	--text-button-1-active: #181818;
	--text-button-1-focus: #181818;
	--text-button-1-disabled: #999999;
	--text-button-2: #ffffff;
	--text-button-2-hover: #ffffff;
	--text-button-2-active: #ffffff;
	--text-button-2-focus: #ffffff;
	--text-button-2-disabled: #999999;
	--text-button-3: #54565b;
	--text-button-3-hover: #54565b;
	--text-button-3-active: #54565b;
	--text-button-3-focus: #54565b;
	--text-button-3-disabled: #999999;
	--text-link-button-4: #181818;
	--text-link-button-4-hover: #181818;
	--text-link-button-4-active: #181818;
	--text-link-button-4-focus: #181818;
	--text-link-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #273878;
	--text-link-1-hover: #192a6b;
	--text-link-1-active: #192a6b;
	--text-link-1-focus: #273878;
	--text-link-1-disabled: #999999;
	--text-link-menu: #181818;
	--text-link-menu-hover: #464646;
	--text-link-menu-active: #181818;
	--text-link-menu-focus: #181818;
	--text-link-menu-disabled: #999999;
	/* border */
	--border-1: #e2e2e2;
	--border-2: #181818;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-1-card-hover: #f2f2f2;
	--bg-2: #f1f1f1;
	--bg-3: #f2f2f2;
	--bg-4: #54565b;
	--bg-disabled: #e0e0e0;
	/* brandColors */
	--brand-1: #181818;
}
[data-theme="onexed-theme"][data-subtheme="default-alt"],
[data-theme="onexed-theme"] [data-subtheme="default-alt"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #426001;
	--error: #be0044;
	--focus-1: #db50fe;
	--focus-2: #db50fe;
	/* text */
	--text-1: #181818;
	--text-2: #707070;
	--text-3: #4d4d4d;
	--text-4: #ffb81c;
	--text-5: #7ba4db;
	--text-6: #0e3e7a;
	--text-7: #a4123f;
	--text-8: #a4123f;
	--text-inverse: #ffffff;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #181818;
	--icon-inverse: #ffffff;
	--icon-disabled: #777777;
	/* backgroundButton */
	--bg-button-1: #efb546;
	--bg-button-1-hover: #efb546;
	--bg-button-1-active: #ffc649;
	--bg-button-1-focus: #ffb81c;
	--bg-button-1-disabled: #cecece;
	--bg-button-2: #54565b;
	--bg-button-2-hover: #383838;
	--bg-button-2-active: #000000;
	--bg-button-2-focus: #181818;
	--bg-button-2-disabled: #cecece;
	--bg-button-3: transparent;
	--bg-button-3-hover: #ffffff;
	--bg-button-3-active: #f6f5ee;
	--bg-button-3-focus: #f6f5ee;
	--bg-button-3-disabled: #cecece;
	--bg-link-button-4: #efb546;
	--bg-link-button-4-hover: #e0a21a;
	--bg-link-button-4-active: #ffc649;
	--bg-link-button-4-focus: #ffb81c;
	--bg-link-button-4-disabled: #cecece;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #db50fe;
	--border-button-1-disabled: #999999;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: #db50fe;
	--border-button-2-disabled: #999999;
	--border-button-3: #54565b;
	--border-button-3-hover: #54565b;
	--border-button-3-active: #54565b;
	--border-button-3-focus: #db50fe;
	--border-button-3-disabled: #999999;
	/* textButton */
	--text-button-1: #181818;
	--text-button-1-hover: #181818;
	--text-button-1-active: #181818;
	--text-button-1-focus: #181818;
	--text-button-1-disabled: #999999;
	--text-button-2: #ffffff;
	--text-button-2-hover: #ffffff;
	--text-button-2-active: #ffffff;
	--text-button-2-focus: #ffffff;
	--text-button-2-disabled: #999999;
	--text-button-3: #54565b;
	--text-button-3-hover: #54565b;
	--text-button-3-active: #54565b;
	--text-button-3-focus: #54565b;
	--text-button-3-disabled: #999999;
	--text-link-button-4: #181818;
	--text-link-button-4-hover: #181818;
	--text-link-button-4-active: #181818;
	--text-link-button-4-focus: #181818;
	--text-link-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #273878;
	--text-link-1-hover: #192a6b;
	--text-link-1-active: #192a6b;
	--text-link-1-focus: #273878;
	--text-link-1-disabled: #999999;
	--text-link-menu: #181818;
	--text-link-menu-hover: #464646;
	--text-link-menu-active: #181818;
	--text-link-menu-focus: #181818;
	--text-link-menu-disabled: #999999;
	/* border */
	--border-1: #e2e2e2;
	--border-2: #181818;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-1-card-hover: #f2f2f2;
	--bg-2: #f1f1f1;
	--bg-3: #f2f2f2;
	--bg-4: #54565b;
	--bg-disabled: #e0e0e0;
	/* brandColors */
	--brand-1: #181818;
	/* background */
	--bg-ui: #f1f1f1;
	--bg-1: #f1f1f1;
	--bg-2: #ffffff;
	--bg-1-card-hover: rgba(24, 24, 24, 0.2);
}
[data-theme="onexed-theme"][data-subtheme="inverse"],
[data-theme="onexed-theme"] [data-subtheme="inverse"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #426001;
	--error: #be0044;
	--focus-1: #db50fe;
	--focus-2: #db50fe;
	/* text */
	--text-1: #181818;
	--text-2: #707070;
	--text-3: #4d4d4d;
	--text-4: #ffb81c;
	--text-5: #7ba4db;
	--text-6: #0e3e7a;
	--text-7: #a4123f;
	--text-8: #a4123f;
	--text-inverse: #ffffff;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #181818;
	--icon-inverse: #ffffff;
	--icon-disabled: #777777;
	/* backgroundButton */
	--bg-button-1: #efb546;
	--bg-button-1-hover: #efb546;
	--bg-button-1-active: #ffc649;
	--bg-button-1-focus: #ffb81c;
	--bg-button-1-disabled: #cecece;
	--bg-button-2: #54565b;
	--bg-button-2-hover: #383838;
	--bg-button-2-active: #000000;
	--bg-button-2-focus: #181818;
	--bg-button-2-disabled: #cecece;
	--bg-button-3: transparent;
	--bg-button-3-hover: #ffffff;
	--bg-button-3-active: #f6f5ee;
	--bg-button-3-focus: #f6f5ee;
	--bg-button-3-disabled: #cecece;
	--bg-link-button-4: #efb546;
	--bg-link-button-4-hover: #e0a21a;
	--bg-link-button-4-active: #ffc649;
	--bg-link-button-4-focus: #ffb81c;
	--bg-link-button-4-disabled: #cecece;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #db50fe;
	--border-button-1-disabled: #999999;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: #db50fe;
	--border-button-2-disabled: #999999;
	--border-button-3: #54565b;
	--border-button-3-hover: #54565b;
	--border-button-3-active: #54565b;
	--border-button-3-focus: #db50fe;
	--border-button-3-disabled: #999999;
	/* textButton */
	--text-button-1: #181818;
	--text-button-1-hover: #181818;
	--text-button-1-active: #181818;
	--text-button-1-focus: #181818;
	--text-button-1-disabled: #999999;
	--text-button-2: #ffffff;
	--text-button-2-hover: #ffffff;
	--text-button-2-active: #ffffff;
	--text-button-2-focus: #ffffff;
	--text-button-2-disabled: #999999;
	--text-button-3: #54565b;
	--text-button-3-hover: #54565b;
	--text-button-3-active: #54565b;
	--text-button-3-focus: #54565b;
	--text-button-3-disabled: #999999;
	--text-link-button-4: #181818;
	--text-link-button-4-hover: #181818;
	--text-link-button-4-active: #181818;
	--text-link-button-4-focus: #181818;
	--text-link-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #273878;
	--text-link-1-hover: #192a6b;
	--text-link-1-active: #192a6b;
	--text-link-1-focus: #273878;
	--text-link-1-disabled: #999999;
	--text-link-menu: #181818;
	--text-link-menu-hover: #464646;
	--text-link-menu-active: #181818;
	--text-link-menu-focus: #181818;
	--text-link-menu-disabled: #999999;
	/* border */
	--border-1: #e2e2e2;
	--border-2: #181818;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-1-card-hover: #f2f2f2;
	--bg-2: #f1f1f1;
	--bg-3: #f2f2f2;
	--bg-4: #54565b;
	--bg-disabled: #e0e0e0;
	/* brandColors */
	--brand-1: #efb546;
	/* veilColors */
	--overlay-0: rgb(239, 181, 70);
	--overlay-1: rgba(239, 181, 70, 0.2);
	--overlay-2: rgba(239, 181, 70, 0.4);
	--overlay-3: rgba(24, 24, 24, 0.2);
	--overlay-4: rgba(24, 24, 24, 0.4);
	/* support */
	--success: #5efb9d;
	--error: #ff1f55;
	--focus-2: #3ee8ff;
	/* text */
	--text-1: #ffffff;
	--text-2: #999999;
	--text-3: #999999;
	--text-4: #efb546;
	--text-5: #ffffff;
	--text-6: #ffffff;
	--text-7: #ffffff;
	--text-8: #ffffff;
	--text-inverse: #ffffff;
	/* icon */
	--icon-1: #ffffff;
	--icon-inverse: #dddddd;
	--icon-disabled: #aaaaaa;
	/* backgroundButton */
	--bg-button-1-hover: #e8a81c;
	--bg-button-2: #ffffff;
	--bg-button-2-hover: #464646;
	--bg-button-3-hover: rgba(255, 255, 255, 0.2);
	--bg-button-3-active: #ffffff;
	--bg-button-3-focus: #181818;
	--bg-link-button-4-hover: #e8a81b;
	/* borderButton */
	--border-button-1-disabled: #838383;
	--border-button-2-disabled: #838383;
	--border-button-3: #ffffff;
	--border-button-3-hover: transparent;
	--border-button-3-active: transparent;
	--border-button-3-disabled: #838383;
	/* textButton */
	--text-button-1-hover: #111111;
	--text-button-1-active: #111111;
	--text-button-1-focus: #111111;
	--text-button-1-disabled: #838383;
	--text-button-2: #111111;
	--text-button-2-disabled: #838383;
	--text-button-3: #ffffff;
	--text-button-3-hover: #ffffff;
	--text-button-3-active: #181818;
	--text-button-3-focus: #ffffff;
	--text-button-3-disabled: #838383;
	--text-link-button-4: #ffffff;
	--text-link-button-4-hover: rgba(255, 255, 255, 0.8);
	--text-link-button-4-active: #efb546;
	--text-link-button-4-focus: #ffffff;
	--text-link-button-4-disabled: #838383;
	/* textLink */
	--text-link-1: #cccccc;
	--text-link-1-hover: #dedede;
	--text-link-1-active: #c4c4c4;
	--text-link-1-focus: #efefef;
	--text-link-menu: #ffffff;
	--text-link-menu-hover: rgba(255, 255, 255, 0.6);
	--text-link-menu-active: #ffffff;
	--text-link-menu-focus: #ffffff;
	/* border */
	--border-1: #383838;
	--border-2: #ffffff;
	/* background */
	--bg-ui: #54565b;
	--bg-1: #54565b;
	--bg-1-card-hover: #181818;
	--bg-2: #181818;
	--bg-3: #000000;
	--bg-4: #000000;
	--bg-disabled: #555555;
}
[data-theme="onexed-theme"][data-subtheme="inverse-alt"],
[data-theme="onexed-theme"] [data-subtheme="inverse-alt"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #426001;
	--error: #be0044;
	--focus-1: #db50fe;
	--focus-2: #db50fe;
	/* text */
	--text-1: #181818;
	--text-2: #707070;
	--text-3: #4d4d4d;
	--text-4: #ffb81c;
	--text-5: #7ba4db;
	--text-6: #0e3e7a;
	--text-7: #a4123f;
	--text-8: #a4123f;
	--text-inverse: #ffffff;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #181818;
	--icon-inverse: #ffffff;
	--icon-disabled: #777777;
	/* backgroundButton */
	--bg-button-1: #efb546;
	--bg-button-1-hover: #efb546;
	--bg-button-1-active: #ffc649;
	--bg-button-1-focus: #ffb81c;
	--bg-button-1-disabled: #cecece;
	--bg-button-2: #54565b;
	--bg-button-2-hover: #383838;
	--bg-button-2-active: #000000;
	--bg-button-2-focus: #181818;
	--bg-button-2-disabled: #cecece;
	--bg-button-3: transparent;
	--bg-button-3-hover: #ffffff;
	--bg-button-3-active: #f6f5ee;
	--bg-button-3-focus: #f6f5ee;
	--bg-button-3-disabled: #cecece;
	--bg-link-button-4: #efb546;
	--bg-link-button-4-hover: #e0a21a;
	--bg-link-button-4-active: #ffc649;
	--bg-link-button-4-focus: #ffb81c;
	--bg-link-button-4-disabled: #cecece;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #db50fe;
	--border-button-1-disabled: #999999;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: #db50fe;
	--border-button-2-disabled: #999999;
	--border-button-3: #54565b;
	--border-button-3-hover: #54565b;
	--border-button-3-active: #54565b;
	--border-button-3-focus: #db50fe;
	--border-button-3-disabled: #999999;
	/* textButton */
	--text-button-1: #181818;
	--text-button-1-hover: #181818;
	--text-button-1-active: #181818;
	--text-button-1-focus: #181818;
	--text-button-1-disabled: #999999;
	--text-button-2: #ffffff;
	--text-button-2-hover: #ffffff;
	--text-button-2-active: #ffffff;
	--text-button-2-focus: #ffffff;
	--text-button-2-disabled: #999999;
	--text-button-3: #54565b;
	--text-button-3-hover: #54565b;
	--text-button-3-active: #54565b;
	--text-button-3-focus: #54565b;
	--text-button-3-disabled: #999999;
	--text-link-button-4: #181818;
	--text-link-button-4-hover: #181818;
	--text-link-button-4-active: #181818;
	--text-link-button-4-focus: #181818;
	--text-link-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #273878;
	--text-link-1-hover: #192a6b;
	--text-link-1-active: #192a6b;
	--text-link-1-focus: #273878;
	--text-link-1-disabled: #999999;
	--text-link-menu: #181818;
	--text-link-menu-hover: #464646;
	--text-link-menu-active: #181818;
	--text-link-menu-focus: #181818;
	--text-link-menu-disabled: #999999;
	/* border */
	--border-1: #e2e2e2;
	--border-2: #181818;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-1-card-hover: #f2f2f2;
	--bg-2: #f1f1f1;
	--bg-3: #f2f2f2;
	--bg-4: #54565b;
	--bg-disabled: #e0e0e0;
	/* brandColors */
	--brand-1: #efb546;
	/* veilColors */
	--overlay-0: rgb(239, 181, 70);
	--overlay-1: rgba(239, 181, 70, 0.2);
	--overlay-2: rgba(239, 181, 70, 0.4);
	--overlay-3: rgba(24, 24, 24, 0.2);
	--overlay-4: rgba(24, 24, 24, 0.4);
	/* support */
	--success: #5efb9d;
	--error: #ff1f55;
	--focus-2: #3ee8ff;
	/* text */
	--text-1: #ffffff;
	--text-2: #999999;
	--text-3: #999999;
	--text-4: #efb546;
	--text-5: #ffffff;
	--text-6: #ffffff;
	--text-7: #ffffff;
	--text-8: #ffffff;
	--text-inverse: #181818;
	/* icon */
	--icon-1: #ffffff;
	--icon-inverse: #dddddd;
	--icon-disabled: #aaaaaa;
	/* backgroundButton */
	--bg-button-1-hover: #e8a81c;
	--bg-button-2: #ffffff;
	--bg-button-2-hover: #464646;
	--bg-button-3-hover: rgba(255, 255, 255, 0.2);
	--bg-button-3-active: #ffffff;
	--bg-button-3-focus: #181818;
	--bg-link-button-4-hover: #e8a81b;
	/* borderButton */
	--border-button-1-disabled: #838383;
	--border-button-2-disabled: #838383;
	--border-button-3: #ffffff;
	--border-button-3-hover: transparent;
	--border-button-3-active: transparent;
	--border-button-3-disabled: #838383;
	/* textButton */
	--text-button-1-hover: #111111;
	--text-button-1-active: #111111;
	--text-button-1-focus: #111111;
	--text-button-1-disabled: #838383;
	--text-button-2: #111111;
	--text-button-2-disabled: #838383;
	--text-button-3: #ffffff;
	--text-button-3-hover: #ffffff;
	--text-button-3-active: #181818;
	--text-button-3-focus: #ffffff;
	--text-button-3-disabled: #838383;
	--text-link-button-4: #ffffff;
	--text-link-button-4-hover: rgba(255, 255, 255, 0.8);
	--text-link-button-4-active: #efb546;
	--text-link-button-4-focus: #ffffff;
	--text-link-button-4-disabled: #838383;
	/* textLink */
	--text-link-1: #cccccc;
	--text-link-1-hover: #dedede;
	--text-link-1-active: #c4c4c4;
	--text-link-1-focus: #efefef;
	--text-link-menu: #ffffff;
	--text-link-menu-hover: rgba(255, 255, 255, 0.6);
	--text-link-menu-active: #ffffff;
	--text-link-menu-focus: #ffffff;
	/* border */
	--border-1: #383838;
	--border-2: #ffffff;
	/* background */
	--bg-ui: #181818;
	--bg-1: #181818;
	--bg-1-card-hover: #54565b;
	--bg-2: #54565b;
	--bg-3: #181818;
	--bg-4: #000000;
	--bg-disabled: #555555;
}
