/* Heading */
.heading-4xl {
	font-family: var(--font-2);
	font-size: 80px;
	font-weight: 500;
	line-height: normal;
	@media (--tablet) {
		font-size: 100px;
	}
	@media (--desktop) {
		font-size: 150px;
	}
	@media (--big-screen) {
		font-size: 170px;
	}
}

.heading-3xl {
	font-family: var(--font-2);
	font-size: 60px;
	font-weight: 500;
	line-height: normal;
	@media (--tablet) {
		font-size: 70px;
	}
	@media (--desktop) {
		font-size: 100px;
	}
	@media (--big-screen) {
		font-size: 126px;
	}
}

.heading-2xl {
	font-family: var(--font-2);
	font-size: 40px;
	line-height: 44px;
	font-weight: 500;
	@media (--tablet) {
		font-size: 56px;
		line-height: 64px;
	}
	@media (--desktop) {
		font-size: 72px;
		line-height: 78px;
	}
	@media (--big-screen) {
		font-size: 80px;
		line-height: 84px;
	}
}

.heading-2xl-italic {
	font-family: var(--font-2);
	font-style: italic;
	font-weight: 500;
	font-size: 40px;
	line-height: 44px;
	@media (--tablet) {
		font-size: 56px;
		line-height: 64px;
	}
	@media (--desktop) {
		font-size: 72px;
		line-height: 78px;
	}
	@media (--big-screen) {
		font-size: 80px;
		line-height: normal;
	}
}

.heading-xl {
	font-family: var(--font-2);
	font-size: 34px;
	line-height: normal;
	font-weight: 500;
	@media (--tablet) {
		font-size: 44px;
	}
	@media (--desktop) {
		font-size: 48px;
	}
	@media (--big-screen) {
		font-size: 54px;
	}
}

.heading-lg {
	font-family: var(--font-2);
	font-size: 30px;
	line-height: 36px;
	font-weight: 500;
	@media (--tablet) {
		font-size: 36px;
		line-height: 48px;
	}
	@media (--desktop) {
		font-size: 40px;
		line-height: 56px;
	}
	@media (--big-screen) {
		font-size: 48px;
		line-height: 64px;
	}
}

.heading-md-italic {
	font-family: var(--font-2);
	font-size: 24px;
	line-height: 32px;
	font-weight: 400;
	font-style: italic;
	@media (--tablet) {
		font-size: 32px;
		line-height: 44px;
	}
	@media (--desktop) {
		font-size: 32px;
		line-height: 44px;
	}
	@media (--big-screen) {
		font-size: 40px;
		line-height: normal;
	}
}

.heading-md {
	font-family: var(--font-2);
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
	@media (--tablet) {
		font-size: 24px;
		line-height: 32px;
	}
}

.heading-sm {
	font-family: var(--font-1);
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
	@media (--desktop) {
		font-size: 20px;
		line-height: 28px;
	}
	@media (--big-screen) {
		font-size: 24px;
		line-height: 30px;
	}
}

.heading-xs {
	font-family: var(--font-1);
	font-size: 16px;
	line-height: 22px;
	font-weight: 600;
	@media (--tablet) {
		font-size: 18px;
		line-height: 22px;
	}
	@media (--desktop) {
		font-size: 18px;
		line-height: 22px;
	}
	@media (--big-screen) {
		font-size: 20px;
		line-height: 26px;
	}
}

/* Highlights */
.highlight-xl {
	font-family: var(--font-1);
	font-style: italic;
	font-size: 40px;
	line-height: 44px;
	@media (--tablet) {
		font-size: 56px;
		line-height: 64px;
	}
	@media (--desktop) {
		font-size: 72px;
		line-height: 78px;
	}
	@media (--big-screen) {
		font-size: 80px;
		line-height: 84px;
	}
}

.highlight-l {
	font-family: var(--font-1);
	font-style: italic;
	font-size: 24px;
	line-height: normal;
	@media (--tablet) {
		font-size: 32px;
	}
	@media (--desktop) {
		font-size: 40px;
	}
	@media (--big-screen) {
		font-size: 48px;
	}
}

.highlight-m {
	font-family: var(--font-1);
	font-style: italic;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	@media (--tablet) {
		font-size: 20px;
		line-height: 26px;
	}
	@media (--desktop) {
		font-size: 22px;
		line-height: 28px;
	}
	@media (--big-screen) {
		font-size: 24px;
		line-height: 30px;
	}
}

.highlight-s {
	font-family: var(--font-1);
	font-style: italic;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	@media (--tablet) {
		line-height: 26px;
	}
	@media (--desktop) {
		font-size: 18px;
		line-height: 28px;
	}
	@media (--big-screen) {
		font-size: 20px;
		line-height: 30px;
	}
}

/* Body */
.body-lead-xl {
	font-family: var(--font-1);
	font-size: 20px;
	line-height: 26px;
	& p {
		margin-bottom: var(--spc-2xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--tablet) {
		font-size: 20px;
		line-height: 28px;
	}
	@media (--desktop) {
		font-size: 22px;
		line-height: 30px;
		& p {
			margin-bottom: var(--spc-xs);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	@media (--big-screen) {
		font-size: 24px;
		line-height: 32px;
	}
}

.body-lead {
	font-family: var(--font-1);
	font-size: 18px;
	line-height: 24px;
	& p {
		margin-bottom: var(--spc-2xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--tablet) {
		line-height: 26px;
	}
	@media (--desktop) {
		font-size: 20px;
		line-height: 26px;
		& p {
			margin-bottom: var(--spc-xs);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	@media (--big-screen) {
		font-size: 20px;
		line-height: 28px;
	}
}

.body {
	font-family: var(--font-1);
	font-size: 16px;
	line-height: 22px;
	& p {
		font-size: 16px;
		line-height: 22px;
		margin-bottom: var(--spc-2xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--tablet) {
		font-size: 17px;
		& p {
			font-size: 17px;
		}
	}
	@media (--desktop) {
		font-size: 18px;
		line-height: 24px;
		& p {
			font-size: 18px;
			line-height: 24px;
			margin-bottom: var(--spc-xs);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	@media (--big-screen) {
		font-size: 18px;
		line-height: 28px;
		& p {
			font-size: 18px;
			line-height: 28px;
		}
	}
}

.body-bold {
	font-family: var(--font-1);
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	& p {
		margin-bottom: var(--spc-2xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--tablet) {
		font-size: 17px;
	}
	@media (--desktop) {
		font-size: 18px;
		line-height: 24px;
		& p {
			margin-bottom: var(--spc-xs);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	@media (--big-screen) {
		font-size: 18px;
		line-height: 28px;
	}
}

.body-additional {
	font-family: var(--font-1);
	font-weight: 600;
	text-transform: uppercase;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.1618em;
	& p {
		margin-bottom: var(--spc-2xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--tablet) {
		font-size: 17px;
	}
	@media (--desktop) {
		font-size: 18px;
		line-height: 24px;
		& p {
			margin-bottom: var(--spc-xs);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	@media (--big-screen) {
		font-size: 18px;
		line-height: 28px;
	}
}

.body-inline-sm {
	font-family: var(--font-1);
	font-size: 14px;
	line-height: 16px;
	& p {
		margin-bottom: var(--spc-3xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--desktop) {
		line-height: 18px;
	}
}

.body-inline-xs {
	font-family: var(--font-1);
	font-size: 12px;
	line-height: 14px;
	& p {
		margin-bottom: var(--spc-3xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--desktop) {
		line-height: 16px;
	}
}

.body-inline-semiBold {
	font-family: var(--font-1);
	font-size: 14px;
	line-height: 18px;
	font-weight: 600;
	& p {
		margin-bottom: var(--spc-3xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.body-inline-italic {
	font-family: var(--font-1);
	font-style: italic;
	font-size: 16px;
	line-height: 22px;
	& p {
		margin-bottom: var(--spc-3xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--tablet) {
		& p {
			margin-bottom: var(--spc-2xs);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

/* Components */
.label-button-md {
	font-family: var(--font-1);
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.label-button-sm {
	font-family: var(--font-1);
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.8px;
	text-transform: uppercase;
	@media (--desktop) {
		line-height: 18px;
		letter-spacing: 0.5px;
	}
}

.label-button-text {
	font-family: var(--font-1);
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.3px;
	@media (--tablet) {
		font-size: 15px;
		line-height: 18px;
		letter-spacing: 0.5px;
	}
	@media (--desktop) {
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.3px;
	}
	@media (--big-screen) {
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.5px;
	}
}

.menu-l {
	font-family: var(--font-1);
	font-size: 14px;
	line-height: normal;
	font-weight: 600;
	@media (--tablet) {
		font-size: 16px;
	}
	@media (--big-screen) {
		font-size: 18px;
	}
}

.menu-m {
	font-family: var(--font-1);
	font-size: 16px;
}

.menu-s {
	font-family: var(--font-1);
	font-size: 14px;
}

.quote {
	font-family: var(--font-1);
	font-size: 20px;
	line-height: 24px;
	font-style: italic;
	@media (--tablet) {
		font-size: 28px;
		line-height: 32px;
	}
	@media (--desktop) {
		font-size: 38px;
		line-height: 44px;
	}
	@media (--big-screen) {
		font-size: 40px;
		line-height: 46px;
	}
}

.breadcrumb-active {
	font-family: var(--font-1);
	font-weight: 600;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.5px;
}

.breadcrumb {
	font-family: var(--font-1);
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.5px;
}
