/* Heading */
[data-theme="onexed-theme"] .heading-4xl {
	font-weight: 300;
}

[data-theme="onexed-theme"] .heading-3xl {
	font-weight: 300;
}

[data-theme="onexed-theme"] .heading-2xl {
	font-weight: 300;
}

[data-theme="onexed-theme"] .heading-2xl-italic {
	font-weight: 300;
}

[data-theme="onexed-theme"] .heading-xl {
	font-weight: 400;
}

[data-theme="onexed-theme"] .heading-lg {
	font-weight: 400;
}

[data-theme="onexed-theme"] .heading-md {
	font-weight: 500;
	line-height: normal;
}
