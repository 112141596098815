/*

  Theme: INEA theme

  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------

  To generate this file:
  1 - Edit `src/themes/inea-theme` files
  2 - Run `yarn build:themes`

*/

[data-theme="inea-theme"] {
}
[data-theme="inea-theme"][data-subtheme="accent"],
[data-theme="inea-theme"] [data-subtheme="accent"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #426001;
	--error: #be0044;
	--focus-1: #db50fe;
	--focus-2: #db50fe;
	/* text */
	--text-1: #181818;
	--text-2: #707070;
	--text-3: #4d4d4d;
	--text-4: #ffb81c;
	--text-5: #7ba4db;
	--text-6: #0e3e7a;
	--text-7: #a4123f;
	--text-8: #a4123f;
	--text-inverse: #ffffff;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #181818;
	--icon-inverse: #ffffff;
	--icon-disabled: #777777;
	/* backgroundButton */
	--bg-button-1: #ffb81c;
	--bg-button-1-hover: #e8a81b;
	--bg-button-1-active: #ffc649;
	--bg-button-1-focus: #ffb81c;
	--bg-button-1-disabled: #cecece;
	--bg-button-2: #181818;
	--bg-button-2-hover: #464646;
	--bg-button-2-active: #000000;
	--bg-button-2-focus: #181818;
	--bg-button-2-disabled: #cecece;
	--bg-button-3: transparent;
	--bg-button-3-hover: #ffffff;
	--bg-button-3-active: #f6f5ee;
	--bg-button-3-focus: #f6f5ee;
	--bg-button-3-disabled: #cecece;
	--bg-link-button-4: #ffb81c;
	--bg-link-button-4-hover: #e8a81b;
	--bg-link-button-4-active: #ffc649;
	--bg-link-button-4-focus: #ffb81c;
	--bg-link-button-4-disabled: #cecece;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #db50fe;
	--border-button-1-disabled: #999999;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: #db50fe;
	--border-button-2-disabled: #999999;
	--border-button-3: #181818;
	--border-button-3-hover: #181818;
	--border-button-3-active: #181818;
	--border-button-3-focus: #db50fe;
	--border-button-3-disabled: #999999;
	/* textButton */
	--text-button-1: #181818;
	--text-button-1-hover: #181818;
	--text-button-1-active: #181818;
	--text-button-1-focus: #181818;
	--text-button-1-disabled: #999999;
	--text-button-2: #ffffff;
	--text-button-2-hover: #ffffff;
	--text-button-2-active: #ffffff;
	--text-button-2-focus: #ffffff;
	--text-button-2-disabled: #999999;
	--text-button-3: #181818;
	--text-button-3-hover: #181818;
	--text-button-3-active: #181818;
	--text-button-3-focus: #181818;
	--text-button-3-disabled: #999999;
	--text-link-button-4: #181818;
	--text-link-button-4-hover: #181818;
	--text-link-button-4-active: #181818;
	--text-link-button-4-focus: #181818;
	--text-link-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #273878;
	--text-link-1-hover: #192a6b;
	--text-link-1-active: #192a6b;
	--text-link-1-focus: #273878;
	--text-link-1-disabled: #999999;
	--text-link-menu: #181818;
	--text-link-menu-hover: #464646;
	--text-link-menu-active: #181818;
	--text-link-menu-focus: #181818;
	--text-link-menu-disabled: #999999;
	/* border */
	--border-1: #e2e2e2;
	--border-2: #181818;
	/* background */
	--bg-ui: #f6f5ee;
	--bg-1: #f6f5ee;
	--bg-1-card-hover: #ffffff;
	--bg-2: #fcfbf6;
	--bg-3: #ffffff;
	--bg-4: #181818;
	--bg-disabled: #e0e0e0;
	/* brandColors */
	--brand-1: #ffffff;
	/* support */
	--success: #00bd08;
	--error: #e02d38;
	--focus-1: #a62bc6;
	/* text */
	--text-1: #ffffff;
	--text-2: #181818;
	--text-3: #ffffff;
	--text-4: #ffffff;
	--text-5: #ffffff;
	--text-6: #ffffff;
	--text-7: #ffffff;
	--text-8: #ffffff;
	--text-disabled: #ababab;
	/* icon */
	--icon-1: #ffffff;
	--icon-disabled: #606060;
	/* backgroundButton */
	--bg-button-1-disabled: #a4a4a4;
	--bg-button-2-disabled: #a4a4a4;
	--bg-button-3-hover: rgba(255, 255, 255, 0.5);
	--bg-button-3-active: #ffffff;
	--bg-button-3-focus: transparent;
	--bg-button-3-disabled: #a4a4a4;
	--bg-link-button-4: #ffffff;
	--bg-link-button-4-hover: #ffffff;
	--bg-link-button-4-active: #ffffff;
	--bg-link-button-4-focus: #ffffff;
	--bg-link-button-4-disabled: #a4a4a4;
	/* borderButton */
	--border-button-1-focus: #a62bc6;
	--border-button-1-disabled: transparent;
	--border-button-2: #ffffff;
	--border-button-2-focus: #a62bc6;
	--border-button-2-disabled: transparent;
	--border-button-3: #ffffff;
	--border-button-3-hover: #ffffff;
	--border-button-3-focus: #a62bc6;
	--border-button-3-disabled: transparent;
	/* textButton */
	--text-button-1-disabled: #5c5c5c;
	--text-button-2-disabled: #5c5c5c;
	--text-button-3: #ffffff;
	--text-button-3-hover: #ffffff;
	--text-button-3-focus: #ffffff;
	--text-button-3-disabled: #5c5c5c;
	--text-link-button-4: #ffffff;
	--text-link-button-4-hover: rgba(255, 255, 255, 0.6);
	--text-link-button-4-focus: #ffffff;
	--text-link-button-4-disabled: #ababab;
	/* textLink */
	--text-link-1: #cccccc;
	--text-link-1-hover: #dedede;
	--text-link-1-active: #c4c4c4;
	--text-link-1-focus: #ffffff;
	--text-link-menu-active: #000000;
	/* border */
	--border-1: #ffffff;
	/* background */
	--bg-ui: #506b1b;
	--bg-1: #506b1b;
	--bg-1-card-hover: #495a27;
	--bg-2: #181818;
	--bg-3: #181818;
	--bg-4: #ffffff;
	--bg-disabled: #bbbbbb;
}
[data-theme="inea-theme"][data-subtheme="accent-alt"],
[data-theme="inea-theme"] [data-subtheme="accent-alt"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #426001;
	--error: #be0044;
	--focus-1: #db50fe;
	--focus-2: #db50fe;
	/* text */
	--text-1: #181818;
	--text-2: #707070;
	--text-3: #4d4d4d;
	--text-4: #ffb81c;
	--text-5: #7ba4db;
	--text-6: #0e3e7a;
	--text-7: #a4123f;
	--text-8: #a4123f;
	--text-inverse: #ffffff;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #181818;
	--icon-inverse: #ffffff;
	--icon-disabled: #777777;
	/* backgroundButton */
	--bg-button-1: #ffb81c;
	--bg-button-1-hover: #e8a81b;
	--bg-button-1-active: #ffc649;
	--bg-button-1-focus: #ffb81c;
	--bg-button-1-disabled: #cecece;
	--bg-button-2: #181818;
	--bg-button-2-hover: #464646;
	--bg-button-2-active: #000000;
	--bg-button-2-focus: #181818;
	--bg-button-2-disabled: #cecece;
	--bg-button-3: transparent;
	--bg-button-3-hover: #ffffff;
	--bg-button-3-active: #f6f5ee;
	--bg-button-3-focus: #f6f5ee;
	--bg-button-3-disabled: #cecece;
	--bg-link-button-4: #ffb81c;
	--bg-link-button-4-hover: #e8a81b;
	--bg-link-button-4-active: #ffc649;
	--bg-link-button-4-focus: #ffb81c;
	--bg-link-button-4-disabled: #cecece;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #db50fe;
	--border-button-1-disabled: #999999;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: #db50fe;
	--border-button-2-disabled: #999999;
	--border-button-3: #181818;
	--border-button-3-hover: #181818;
	--border-button-3-active: #181818;
	--border-button-3-focus: #db50fe;
	--border-button-3-disabled: #999999;
	/* textButton */
	--text-button-1: #181818;
	--text-button-1-hover: #181818;
	--text-button-1-active: #181818;
	--text-button-1-focus: #181818;
	--text-button-1-disabled: #999999;
	--text-button-2: #ffffff;
	--text-button-2-hover: #ffffff;
	--text-button-2-active: #ffffff;
	--text-button-2-focus: #ffffff;
	--text-button-2-disabled: #999999;
	--text-button-3: #181818;
	--text-button-3-hover: #181818;
	--text-button-3-active: #181818;
	--text-button-3-focus: #181818;
	--text-button-3-disabled: #999999;
	--text-link-button-4: #181818;
	--text-link-button-4-hover: #181818;
	--text-link-button-4-active: #181818;
	--text-link-button-4-focus: #181818;
	--text-link-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #273878;
	--text-link-1-hover: #192a6b;
	--text-link-1-active: #192a6b;
	--text-link-1-focus: #273878;
	--text-link-1-disabled: #999999;
	--text-link-menu: #181818;
	--text-link-menu-hover: #464646;
	--text-link-menu-active: #181818;
	--text-link-menu-focus: #181818;
	--text-link-menu-disabled: #999999;
	/* border */
	--border-1: #e2e2e2;
	--border-2: #181818;
	/* background */
	--bg-ui: #f6f5ee;
	--bg-1: #f6f5ee;
	--bg-1-card-hover: #ffffff;
	--bg-2: #fcfbf6;
	--bg-3: #ffffff;
	--bg-4: #181818;
	--bg-disabled: #e0e0e0;
	/* brandColors */
	--brand-1: #506b1b;
	/* backgroundButton */
	--bg-link-button-4: #506b1b;
	--bg-link-button-4-hover: #506b1b;
	--bg-link-button-4-active: #506b1b;
	--bg-link-button-4-focus: #506b1b;
	/* background */
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-1-card-hover: #f6f5ee;
	--bg-2: #506b1b;
}
[data-theme="inea-theme"][data-subtheme="default"],
[data-theme="inea-theme"] [data-subtheme="default"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #426001;
	--error: #be0044;
	--focus-1: #db50fe;
	--focus-2: #db50fe;
	/* text */
	--text-1: #181818;
	--text-2: #707070;
	--text-3: #4d4d4d;
	--text-4: #ffb81c;
	--text-5: #7ba4db;
	--text-6: #0e3e7a;
	--text-7: #a4123f;
	--text-8: #a4123f;
	--text-inverse: #ffffff;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #181818;
	--icon-inverse: #ffffff;
	--icon-disabled: #777777;
	/* backgroundButton */
	--bg-button-1: #ffb81c;
	--bg-button-1-hover: #e8a81b;
	--bg-button-1-active: #ffc649;
	--bg-button-1-focus: #ffb81c;
	--bg-button-1-disabled: #cecece;
	--bg-button-2: #181818;
	--bg-button-2-hover: #464646;
	--bg-button-2-active: #000000;
	--bg-button-2-focus: #181818;
	--bg-button-2-disabled: #cecece;
	--bg-button-3: transparent;
	--bg-button-3-hover: #ffffff;
	--bg-button-3-active: #f6f5ee;
	--bg-button-3-focus: #f6f5ee;
	--bg-button-3-disabled: #cecece;
	--bg-link-button-4: #ffb81c;
	--bg-link-button-4-hover: #e8a81b;
	--bg-link-button-4-active: #ffc649;
	--bg-link-button-4-focus: #ffb81c;
	--bg-link-button-4-disabled: #cecece;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #db50fe;
	--border-button-1-disabled: #999999;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: #db50fe;
	--border-button-2-disabled: #999999;
	--border-button-3: #181818;
	--border-button-3-hover: #181818;
	--border-button-3-active: #181818;
	--border-button-3-focus: #db50fe;
	--border-button-3-disabled: #999999;
	/* textButton */
	--text-button-1: #181818;
	--text-button-1-hover: #181818;
	--text-button-1-active: #181818;
	--text-button-1-focus: #181818;
	--text-button-1-disabled: #999999;
	--text-button-2: #ffffff;
	--text-button-2-hover: #ffffff;
	--text-button-2-active: #ffffff;
	--text-button-2-focus: #ffffff;
	--text-button-2-disabled: #999999;
	--text-button-3: #181818;
	--text-button-3-hover: #181818;
	--text-button-3-active: #181818;
	--text-button-3-focus: #181818;
	--text-button-3-disabled: #999999;
	--text-link-button-4: #181818;
	--text-link-button-4-hover: #181818;
	--text-link-button-4-active: #181818;
	--text-link-button-4-focus: #181818;
	--text-link-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #273878;
	--text-link-1-hover: #192a6b;
	--text-link-1-active: #192a6b;
	--text-link-1-focus: #273878;
	--text-link-1-disabled: #999999;
	--text-link-menu: #181818;
	--text-link-menu-hover: #464646;
	--text-link-menu-active: #181818;
	--text-link-menu-focus: #181818;
	--text-link-menu-disabled: #999999;
	/* border */
	--border-1: #e2e2e2;
	--border-2: #181818;
	/* background */
	--bg-ui: #f6f5ee;
	--bg-1: #f6f5ee;
	--bg-1-card-hover: #ffffff;
	--bg-2: #fcfbf6;
	--bg-3: #ffffff;
	--bg-4: #181818;
	--bg-disabled: #e0e0e0;
	/* brandColors */
	--brand-1: #506b1b;
	/* backgroundButton */
	--bg-link-button-4: #506b1b;
	--bg-link-button-4-hover: #506b1b;
	--bg-link-button-4-active: #506b1b;
	--bg-link-button-4-focus: #506b1b;
}
[data-theme="inea-theme"][data-subtheme="default-alt"],
[data-theme="inea-theme"] [data-subtheme="default-alt"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #426001;
	--error: #be0044;
	--focus-1: #db50fe;
	--focus-2: #db50fe;
	/* text */
	--text-1: #181818;
	--text-2: #707070;
	--text-3: #4d4d4d;
	--text-4: #ffb81c;
	--text-5: #7ba4db;
	--text-6: #0e3e7a;
	--text-7: #a4123f;
	--text-8: #a4123f;
	--text-inverse: #ffffff;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #181818;
	--icon-inverse: #ffffff;
	--icon-disabled: #777777;
	/* backgroundButton */
	--bg-button-1: #ffb81c;
	--bg-button-1-hover: #e8a81b;
	--bg-button-1-active: #ffc649;
	--bg-button-1-focus: #ffb81c;
	--bg-button-1-disabled: #cecece;
	--bg-button-2: #181818;
	--bg-button-2-hover: #464646;
	--bg-button-2-active: #000000;
	--bg-button-2-focus: #181818;
	--bg-button-2-disabled: #cecece;
	--bg-button-3: transparent;
	--bg-button-3-hover: #ffffff;
	--bg-button-3-active: #f6f5ee;
	--bg-button-3-focus: #f6f5ee;
	--bg-button-3-disabled: #cecece;
	--bg-link-button-4: #ffb81c;
	--bg-link-button-4-hover: #e8a81b;
	--bg-link-button-4-active: #ffc649;
	--bg-link-button-4-focus: #ffb81c;
	--bg-link-button-4-disabled: #cecece;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #db50fe;
	--border-button-1-disabled: #999999;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: #db50fe;
	--border-button-2-disabled: #999999;
	--border-button-3: #181818;
	--border-button-3-hover: #181818;
	--border-button-3-active: #181818;
	--border-button-3-focus: #db50fe;
	--border-button-3-disabled: #999999;
	/* textButton */
	--text-button-1: #181818;
	--text-button-1-hover: #181818;
	--text-button-1-active: #181818;
	--text-button-1-focus: #181818;
	--text-button-1-disabled: #999999;
	--text-button-2: #ffffff;
	--text-button-2-hover: #ffffff;
	--text-button-2-active: #ffffff;
	--text-button-2-focus: #ffffff;
	--text-button-2-disabled: #999999;
	--text-button-3: #181818;
	--text-button-3-hover: #181818;
	--text-button-3-active: #181818;
	--text-button-3-focus: #181818;
	--text-button-3-disabled: #999999;
	--text-link-button-4: #181818;
	--text-link-button-4-hover: #181818;
	--text-link-button-4-active: #181818;
	--text-link-button-4-focus: #181818;
	--text-link-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #273878;
	--text-link-1-hover: #192a6b;
	--text-link-1-active: #192a6b;
	--text-link-1-focus: #273878;
	--text-link-1-disabled: #999999;
	--text-link-menu: #181818;
	--text-link-menu-hover: #464646;
	--text-link-menu-active: #181818;
	--text-link-menu-focus: #181818;
	--text-link-menu-disabled: #999999;
	/* border */
	--border-1: #e2e2e2;
	--border-2: #181818;
	/* background */
	--bg-ui: #f6f5ee;
	--bg-1: #f6f5ee;
	--bg-1-card-hover: #ffffff;
	--bg-2: #fcfbf6;
	--bg-3: #ffffff;
	--bg-4: #181818;
	--bg-disabled: #e0e0e0;
	/* brandColors */
	--brand-1: #506b1b;
	/* backgroundButton */
	--bg-link-button-4: #506b1b;
	--bg-link-button-4-hover: #506b1b;
	--bg-link-button-4-active: #506b1b;
	--bg-link-button-4-focus: #506b1b;
	/* background */
	--bg-ui: #fcfbf6;
	--bg-1: #fcfbf6;
	--bg-2: #f6f5ee;
}
[data-theme="inea-theme"][data-subtheme="inverse"],
[data-theme="inea-theme"] [data-subtheme="inverse"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #426001;
	--error: #be0044;
	--focus-1: #db50fe;
	--focus-2: #db50fe;
	/* text */
	--text-1: #181818;
	--text-2: #707070;
	--text-3: #4d4d4d;
	--text-4: #ffb81c;
	--text-5: #7ba4db;
	--text-6: #0e3e7a;
	--text-7: #a4123f;
	--text-8: #a4123f;
	--text-inverse: #ffffff;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #181818;
	--icon-inverse: #ffffff;
	--icon-disabled: #777777;
	/* backgroundButton */
	--bg-button-1: #ffb81c;
	--bg-button-1-hover: #e8a81b;
	--bg-button-1-active: #ffc649;
	--bg-button-1-focus: #ffb81c;
	--bg-button-1-disabled: #cecece;
	--bg-button-2: #181818;
	--bg-button-2-hover: #464646;
	--bg-button-2-active: #000000;
	--bg-button-2-focus: #181818;
	--bg-button-2-disabled: #cecece;
	--bg-button-3: transparent;
	--bg-button-3-hover: #ffffff;
	--bg-button-3-active: #f6f5ee;
	--bg-button-3-focus: #f6f5ee;
	--bg-button-3-disabled: #cecece;
	--bg-link-button-4: #ffb81c;
	--bg-link-button-4-hover: #e8a81b;
	--bg-link-button-4-active: #ffc649;
	--bg-link-button-4-focus: #ffb81c;
	--bg-link-button-4-disabled: #cecece;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #db50fe;
	--border-button-1-disabled: #999999;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: #db50fe;
	--border-button-2-disabled: #999999;
	--border-button-3: #181818;
	--border-button-3-hover: #181818;
	--border-button-3-active: #181818;
	--border-button-3-focus: #db50fe;
	--border-button-3-disabled: #999999;
	/* textButton */
	--text-button-1: #181818;
	--text-button-1-hover: #181818;
	--text-button-1-active: #181818;
	--text-button-1-focus: #181818;
	--text-button-1-disabled: #999999;
	--text-button-2: #ffffff;
	--text-button-2-hover: #ffffff;
	--text-button-2-active: #ffffff;
	--text-button-2-focus: #ffffff;
	--text-button-2-disabled: #999999;
	--text-button-3: #181818;
	--text-button-3-hover: #181818;
	--text-button-3-active: #181818;
	--text-button-3-focus: #181818;
	--text-button-3-disabled: #999999;
	--text-link-button-4: #181818;
	--text-link-button-4-hover: #181818;
	--text-link-button-4-active: #181818;
	--text-link-button-4-focus: #181818;
	--text-link-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #273878;
	--text-link-1-hover: #192a6b;
	--text-link-1-active: #192a6b;
	--text-link-1-focus: #273878;
	--text-link-1-disabled: #999999;
	--text-link-menu: #181818;
	--text-link-menu-hover: #464646;
	--text-link-menu-active: #181818;
	--text-link-menu-focus: #181818;
	--text-link-menu-disabled: #999999;
	/* border */
	--border-1: #e2e2e2;
	--border-2: #181818;
	/* background */
	--bg-ui: #f6f5ee;
	--bg-1: #f6f5ee;
	--bg-1-card-hover: #ffffff;
	--bg-2: #fcfbf6;
	--bg-3: #ffffff;
	--bg-4: #181818;
	--bg-disabled: #e0e0e0;
	/* brandColors */
	--brand-1: #ffb81c;
	/* veilColors */
	--overlay-0: rgba(255, 246, 227);
	--overlay-1: rgba(255, 246, 227, 0.2);
	--overlay-2: rgba(255, 246, 227, 0.4);
	--overlay-3: rgba(24, 24, 24, 0.2);
	--overlay-4: rgba(24, 24, 24, 0.4);
	/* support */
	--success: #5efb9d;
	--error: #ff1f55;
	--focus-1: #db50fe;
	--focus-2: #3ee8ff;
	/* text */
	--text-1: #ffffff;
	--text-2: #999999;
	--text-3: #b3b3b3;
	--text-5: #ffffff;
	--text-6: #ffffff;
	--text-7: #ffffff;
	--text-8: #ffffff;
	--text-inverse: #181818;
	/* icon */
	--icon-1: #ffffff;
	--icon-inverse: #dddddd;
	--icon-disabled: #aaaaaa;
	/* backgroundButton */
	--bg-button-1-hover: #e8a81c;
	--bg-button-2: #ffffff;
	--bg-button-3-hover: rgba(255, 255, 255, 0.2);
	--bg-button-3-active: #ffffff;
	--bg-button-3-focus: #181818;
	/* borderButton */
	--border-button-1-disabled: #838383;
	--border-button-2-disabled: #838383;
	--border-button-3: #ffffff;
	--border-button-3-hover: transparent;
	--border-button-3-active: transparent;
	--border-button-3-disabled: #838383;
	/* textButton */
	--text-button-1-hover: #111111;
	--text-button-1-active: #111111;
	--text-button-1-focus: #111111;
	--text-button-1-disabled: #838383;
	--text-button-2: #111111;
	--text-button-2-disabled: #838383;
	--text-button-3: #ffffff;
	--text-button-3-hover: #ffffff;
	--text-button-3-focus: #ffffff;
	--text-button-3-disabled: #838383;
	--text-link-button-4: #ffffff;
	--text-link-button-4-hover: #ffffff;
	--text-link-button-4-active: #ffc649;
	--text-link-button-4-focus: #ffffff;
	--text-link-button-4-disabled: #838383;
	/* textLink */
	--text-link-1: #cccccc;
	--text-link-1-hover: #dedede;
	--text-link-1-active: #c4c4c4;
	--text-link-1-focus: #efefef;
	--text-link-menu: #ffffff;
	--text-link-menu-hover: rgba(255, 255, 255, 0.6);
	--text-link-menu-active: #ffffff;
	--text-link-menu-focus: #ffffff;
	/* border */
	--border-1: #383838;
	--border-2: #ffffff;
	/* background */
	--bg-ui: #181818;
	--bg-1: #181818;
	--bg-1-card-hover: #000000;
	--bg-2: #000000;
	--bg-3: #181818;
	--bg-4: #ffffff;
	--bg-disabled: #555555;
	/* brandColors */
	--brand-1: #506b1b;
	/* backgroundButton */
	--bg-link-button-4: #506b1b;
	--bg-link-button-4-hover: #506b1b;
	--bg-link-button-4-active: #506b1b;
	--bg-link-button-4-focus: #506b1b;
}
[data-theme="inea-theme"][data-subtheme="inverse-alt"],
[data-theme="inea-theme"] [data-subtheme="inverse-alt"] {
	/* veilColors */
	--overlay-0: rgb(24, 24, 24);
	--overlay-1: rgba(24, 24, 24, 0.2);
	--overlay-2: rgba(24, 24, 24, 0.4);
	--overlay-3: rgba(255, 246, 227, 0.2);
	--overlay-4: rgba(255, 251, 241, 0.4);
	/* shadowColor */
	--shadow-1: 14, 47, 132;
	--shadow-2: 1, 9, 30;
	/* support */
	--success: #426001;
	--error: #be0044;
	--focus-1: #db50fe;
	--focus-2: #db50fe;
	/* text */
	--text-1: #181818;
	--text-2: #707070;
	--text-3: #4d4d4d;
	--text-4: #ffb81c;
	--text-5: #7ba4db;
	--text-6: #0e3e7a;
	--text-7: #a4123f;
	--text-8: #a4123f;
	--text-inverse: #ffffff;
	--text-disabled: #999999;
	/* icon */
	--icon-1: #181818;
	--icon-inverse: #ffffff;
	--icon-disabled: #777777;
	/* backgroundButton */
	--bg-button-1: #ffb81c;
	--bg-button-1-hover: #e8a81b;
	--bg-button-1-active: #ffc649;
	--bg-button-1-focus: #ffb81c;
	--bg-button-1-disabled: #cecece;
	--bg-button-2: #181818;
	--bg-button-2-hover: #464646;
	--bg-button-2-active: #000000;
	--bg-button-2-focus: #181818;
	--bg-button-2-disabled: #cecece;
	--bg-button-3: transparent;
	--bg-button-3-hover: #ffffff;
	--bg-button-3-active: #f6f5ee;
	--bg-button-3-focus: #f6f5ee;
	--bg-button-3-disabled: #cecece;
	--bg-link-button-4: #ffb81c;
	--bg-link-button-4-hover: #e8a81b;
	--bg-link-button-4-active: #ffc649;
	--bg-link-button-4-focus: #ffb81c;
	--bg-link-button-4-disabled: #cecece;
	/* borderButton */
	--border-button-1: transparent;
	--border-button-1-hover: transparent;
	--border-button-1-active: transparent;
	--border-button-1-focus: #db50fe;
	--border-button-1-disabled: #999999;
	--border-button-2: transparent;
	--border-button-2-hover: transparent;
	--border-button-2-active: transparent;
	--border-button-2-focus: #db50fe;
	--border-button-2-disabled: #999999;
	--border-button-3: #181818;
	--border-button-3-hover: #181818;
	--border-button-3-active: #181818;
	--border-button-3-focus: #db50fe;
	--border-button-3-disabled: #999999;
	/* textButton */
	--text-button-1: #181818;
	--text-button-1-hover: #181818;
	--text-button-1-active: #181818;
	--text-button-1-focus: #181818;
	--text-button-1-disabled: #999999;
	--text-button-2: #ffffff;
	--text-button-2-hover: #ffffff;
	--text-button-2-active: #ffffff;
	--text-button-2-focus: #ffffff;
	--text-button-2-disabled: #999999;
	--text-button-3: #181818;
	--text-button-3-hover: #181818;
	--text-button-3-active: #181818;
	--text-button-3-focus: #181818;
	--text-button-3-disabled: #999999;
	--text-link-button-4: #181818;
	--text-link-button-4-hover: #181818;
	--text-link-button-4-active: #181818;
	--text-link-button-4-focus: #181818;
	--text-link-button-4-disabled: #999999;
	/* textLink */
	--text-link-1: #273878;
	--text-link-1-hover: #192a6b;
	--text-link-1-active: #192a6b;
	--text-link-1-focus: #273878;
	--text-link-1-disabled: #999999;
	--text-link-menu: #181818;
	--text-link-menu-hover: #464646;
	--text-link-menu-active: #181818;
	--text-link-menu-focus: #181818;
	--text-link-menu-disabled: #999999;
	/* border */
	--border-1: #e2e2e2;
	--border-2: #181818;
	/* background */
	--bg-ui: #f6f5ee;
	--bg-1: #f6f5ee;
	--bg-1-card-hover: #ffffff;
	--bg-2: #fcfbf6;
	--bg-3: #ffffff;
	--bg-4: #181818;
	--bg-disabled: #e0e0e0;
	/* brandColors */
	--brand-1: #ffb81c;
	/* veilColors */
	--overlay-0: rgba(255, 246, 227);
	--overlay-1: rgba(255, 246, 227, 0.2);
	--overlay-2: rgba(255, 246, 227, 0.4);
	--overlay-3: rgba(24, 24, 24, 0.2);
	--overlay-4: rgba(24, 24, 24, 0.4);
	/* support */
	--success: #5efb9d;
	--error: #ff1f55;
	--focus-1: #db50fe;
	--focus-2: #3ee8ff;
	/* text */
	--text-1: #ffffff;
	--text-5: #ffffff;
	--text-6: #ffffff;
	--text-7: #ffffff;
	--text-8: #ffffff;
	--text-inverse: #181818;
	/* icon */
	--icon-1: #ffffff;
	--icon-inverse: #dddddd;
	--icon-disabled: #aaaaaa;
	/* backgroundButton */
	--bg-button-1-hover: #e8a81c;
	--bg-button-2: #ffffff;
	--bg-button-3-hover: rgba(255, 255, 255, 0.2);
	--bg-button-3-active: #ffffff;
	--bg-button-3-focus: #181818;
	/* borderButton */
	--border-button-1-disabled: #838383;
	--border-button-2-disabled: #838383;
	--border-button-3: #ffffff;
	--border-button-3-hover: transparent;
	--border-button-3-active: transparent;
	--border-button-3-disabled: #838383;
	/* textButton */
	--text-button-1-hover: #111111;
	--text-button-1-active: #111111;
	--text-button-1-focus: #111111;
	--text-button-1-disabled: #838383;
	--text-button-2: #111111;
	--text-button-2-disabled: #838383;
	--text-button-3: #ffffff;
	--text-button-3-hover: #ffffff;
	--text-button-3-focus: #ffffff;
	--text-button-3-disabled: #838383;
	--text-link-button-4: #ffffff;
	--text-link-button-4-hover: #ffffff;
	--text-link-button-4-active: #ffc649;
	--text-link-button-4-focus: #ffffff;
	--text-link-button-4-disabled: #838383;
	/* textLink */
	--text-link-1: #cccccc;
	--text-link-1-hover: #dedede;
	--text-link-1-active: #c4c4c4;
	--text-link-1-focus: #efefef;
	--text-link-menu: #ffffff;
	--text-link-menu-hover: rgba(255, 255, 255, 0.6);
	--text-link-menu-active: #ffffff;
	--text-link-menu-focus: #ffffff;
	/* border */
	--border-1: #383838;
	--border-2: #ffffff;
	/* background */
	--bg-ui: #000000;
	--bg-1: #000000;
	--bg-1-card-hover: #181818;
	--bg-2: #181818;
	--bg-3: #000000;
	--bg-4: #ffffff;
	--bg-disabled: #555555;
	/* brandColors */
	--brand-1: #506b1b;
	/* backgroundButton */
	--bg-link-button-4: #506b1b;
	--bg-link-button-4-hover: #506b1b;
	--bg-link-button-4-active: #506b1b;
	--bg-link-button-4-focus: #506b1b;
}
